/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />

declare var ga: any;

function gaEvent(data: any) {
    ga('send', 'event', data.catagory, data.action, data.label, data.value);
}

function gaProductClick(element: JQuery) {
    var data = element.data();
    gaProductClickNoEvent(element);
    ga('send', 'event', 'Product Click', 'Click', data.name);
}

function gaProductClickNoEvent(element: JQuery) {
    var data = element.data();
    data.list = element.parents('.product-impression-list').data('list-name');
    ga('ec:addProduct', data);
    ga('ec:setAction', 'click', { 'list': data.list });
}

function gaPromotionClick(data: any) {
    ga('ec:addPromo', data);
    ga('ec:setAction', 'promo_click');
    ga('send', 'event', 'Promotion Click', 'Click', data.name);
}

function gaQueueProductImpressions(container: JQuery) {
    var dataToSend = false;
    container.find('.product-impression-list').each(function (i, e) {
        var listName = $(e).data('list-name');
        $(this).find('.product-impression').each((i, e) => {
            var impression = $(e).data();
            impression.list = listName;
            impression.position = i + 1;
            ga('ec:addImpression', impression);
            dataToSend = true;
        });
    });

    //this should find all product impressions not in a list
    container.find('.product-impression').not(".product-impression-list").each(function (i, e) {
        $(this).find('.product-impression').each((i, e) => {
            var impression = $(e).data();
            impression.list = "Miscellaneous";
            ga('ec:addImpression', impression);
            dataToSend = true;
        });
    });

    return dataToSend;
}

function gaQueuePromotionImpressions(container: JQuery) {
    var dataToSend = false;

    container.find('.promotion-impression').each((i, e) => {
        var impression = $(e).data();
        ga('ec:addPromo', impression);
        dataToSend = true;
    });

    return dataToSend;
}

function addClickEventToAddToCart(addToCartDiv : JQuery) {
    addToCartDiv.find('button.add-to-cart').click(function () {
        var data = $(this).data();
        data.list = $(this).parents('.product-impression-list').data('list-name');

        var impression = $(this).closest('.product-impression');
        if (impression.length !== 1 && data.list != undefined) {
            impression = $(this).parents('.product-impression-list').find(".product-impression[data-id='" + data.id + "']");
        }

        if (impression.length === 1) {
            gaProductClickNoEvent(impression);
            ga('send', 'event', 'Product Click', 'Click', data.name, { 'nonInteraction': 1 });
        }

        // The actual 'add' event is generated by the server and is logged on page load or as part of the AJAX response
    });
}

$(document).ready(() => {
    gaQueueProductImpressions($(document));
    gaQueuePromotionImpressions($(document));
    ga('send', 'pageview');

    $('button.add-to-cart').each(function() {
        addClickEventToAddToCart($(this));
    });

    //Not Sure if this is right
    $('.product-impression a').click(function () {
        if (typeof gaProductClick != "undefined") {
            gaProductClick($(this).closest('.product-impression'));
        }
    });

    $('.promotion-impression a').click(function () {
        if (typeof gaPromotionClick != "undefined") {
            gaPromotionClick($(this).closest('.promotion-impression').data());
        }
    });

    $('.promotion-impression form').submit(function () {
        if (typeof gaPromotionClick != "undefined") {
            gaPromotionClick($(this).closest('.promotion-impression').data());
        }
    });

    $('a.ga-event, ga-event a').click(function () {
        if (typeof gaEvent != "undefined") {
            gaEvent($(this).closest('.ga-event').data());
        }
    });

    $('form.ga-event, ga-event form').submit(function () {
        if (typeof gaEvent != "undefined") {
            gaEvent($(this).closest('.ga-event').data());
        }
    });
});

$(document).on('cider.core.productsLoaded', (e, container) => {
    if (gaQueueProductImpressions($(container))) {
        ga('send', 'event', 'Ecommerce', 'Impression', 'Products',{ 'nonInteraction': 1 });
    }
});

$(document).on('cider.catalog.thumbnailLinkClicked', (e, mouseEvent) => {
    gaProductClick($(mouseEvent.currentTarget).closest('.product-impression'));
});

$(document).on('cider.core.promotionsLoaded', (e, container) => {
    if (gaQueuePromotionImpressions($(container))) {
        ga('send', 'event', 'Ecommerce', 'Impression', 'Promotions',{ 'nonInteraction': 1 });
    }
});

$(document).on('cider.shoppingCart.addToCartInitialized', (e, container) => {
    addClickEventToAddToCart($(container));
});

